import React from "react";
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Container,
  Form,
  Label,
  FormGroup,
  FormText,
  FormFeedback,
  Input,
  Button,
  Col,
  Row
} from 'reactstrap';
import { Link } from 'gatsby';
import ErrorAccessPageSvg from '../media/404.svg';
import HeadMeta from '../components/HeadMeta';

const {
  REACT_COMPANY_NAME
} = process.env;

const metaData = {
  en: {
    title: `TriDyme | 404 Page`,
    description: "Error Page"
  },
  fr: {
    title: `TriDyme | 404 Page`,
    description: "Page Erreur"
  }
};

const ErrorAccessPage = ({ pageContext: { locale } }) => (
  <div>
    <HeadMeta 
      locale={locale} 
      metaData={metaData}
    />
    <Container className="contactContainer">
      <Col md={{ size: 10, offset: 1 }}>
        <Card className="card">
          <CardBody>
            <Row>
              <Col md={6}>
                <div >
                  <img 
                    className="img" 
                    src={ErrorAccessPageSvg}
                    alt="404" 
                  />
                </div>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="title">We are sorry this Page doesn't exist</Label>
                </FormGroup>
                <Button tag={Link} to="/" className="positiveButton"> 
                  Back to Home Page
                </Button>           
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Container>
  </div>
);

ErrorAccessPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default ErrorAccessPage;